.video-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.video-wrap {
  display: grid;
  height: 90%;
  width: 100%;
  padding: 10px;
  grid-gap: 10px;
  overflow-y: auto;
}
.flip {
  transform: rotateY(180deg);
}
.logo {
  position: absolute;
  z-index: 1000;
  bottom: 10px;
  left: 10px;
}
.video {
  object-fit: contain;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.one {
  grid-template-columns: 1fr;
  grid-template-rows: 100%;
}

.two {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 100%);
}

.three {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(1, 100%);
}

.default-grid {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 300px;
}

.video_wrap {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #312e33;
}
.default-grid .video_wrap {
  width: 100%;
  height: 300px;
  /* height: 100%; */
}

.video_wrap video {
  width: 100%;
  height: 100%;
}

.participant-name {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 11;
  color: #fff;
}

.participants-avatar span {
  /* display: block; */
  width: 70px;
  height: 70px;
  background-color: whitesmoke;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-weight: 700;
  font-size: 30px;
}
.status-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 11;
}
.participants-avatar {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  background-color: #142026;
  text-align: center;
  width: 100%;
  height: 100%;
  color: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
}

.startmeet {
  /* height: 100vh; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.label {
  display: flex;
  margin: 10px 0px;
  font-weight: 100;
  margin-left: 3px !important;
  /* margin-top: 3px; */
}
.startmeet_p {
  width: 100%;
}
.start-container {
  width: 100%;
}

.navbar {
  height: 40px;
}
.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bottom button:nth-child(1) {
  font-size: 15px !important;
  border-radius: 4px;
  outline: none;
  border: none;
  padding: 10px 15px;
  background-color: #d92029;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
}
.bottom button:nth-child(3) {
  font-size: 15px !important;
  background-color: transparent;
  font-family: inherit;
  border: none;
  outline: none;
  font-weight: 600;
  cursor: pointer;
}
.startmeet .form {
  display: flex;
  flex-direction: column;
  width: 26%;
  /* height: 180px; */
  border-radius: 7px;
  justify-content: space-between;
  /* align-items: center; */
  padding: 40px 15px;
}

.startmeet .form .field {
  padding: 10px 12px;
  font-family: sans-serif;
  border: 1px solid lightgrey;
  border-radius: 4px;
  border-radius: 4px;
  font-family: inherit;
  margin-bottom: 20px;
}

.startmeet p {
  /* text-align: center; */
  width: 100%;
  margin-bottom: 20px;
}
.startmeet form button {
  /* padding: 10px 12px;
    font-family: sans-serif;
    outline: none;
    font-weight: 600;
    font-size: 16px;
    border: none;
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
    border-radius: 4px; */
  /* margin-top: 20px; */
  /* font-family: inherit; */
}

.action-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10%;
  width: 23%;
  justify-self: center;
  align-self: center;
}

.btn {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* color: #fff; */
  background-color: #fff;
  box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.2);
}

.danger {
  background-color: red;
  color: #fff !important;
}

.popup-box {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.4);
}
.box {
  position: relative;
  width: 30%;
  height: 80%;
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 1450px) {
  .startmeet .form {
    width: 38% !important;
  }
}
@media only screen and (max-width: 1050px) {
  .startmeet .form {
    width: 45% !important;
  }
}
@media only screen and (max-width: 850px) {
  .startmeet .form {
    width: 55% !important;
  }
}
@media only screen and (max-width: 770px) {
  .startmeet {
    /* width: 90%; */
  }
  .startmeet .form {
    width: 90% !important;
  }
  .two {
    display: flex;
    flex-direction: column;
  }

  .two .video_wrap {
    height: 50%;
  }

  .three,
  .default-grid {
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: minmax(300px, 300px);
    /* grid-template-rows: 300px; */
    grid-template-rows: none;
  }
  .action-btn {
    width: 82%;
  }

  .participants-avatar span {
    width: 40px;
    height: 40px;
    font-size: 26px;
  }

  .box {
    width: 90%;
  }
}

.box_info {
  width: 100%;
  /* background-color: red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.box_info:not(:last-child) {
  border-bottom: 1px solid lightgrey;
}

.box_name {
  display: flex;
  align-items: center;
}

.box_name p {
  margin-left: 5px;
  font-weight: 700;
  font-size: 14px;
}

.box::-webkit-scrollbar-track {
  background-color: #fff;
}

.box::-webkit-scrollbar {
  width: 6px;
}
.box::-webkit-scrollbar-thumb {
  background-color: #142026;
  border-radius: 10px;
}

@media only screen and (max-width: 600px) {
  .startmeet {
    width: 100%;
  }
  .bottom button:nth-child(1) {
    font-size: 13px !important;
    padding: 8px 12px !important;
  }
  .bottom button:nth-child(3) {
    font-size: 13px !important;
  }
  .bottom input {
    font-size: 13px !important;
    padding: 8px 12px !important;
  }
}
@media only screen and (max-width: 400px) {
  .startmeet {
    width: 100%;
  }
  .bottom button:nth-child(1) {
    font-size: 11px !important;
    padding: 12px 10px !important;
  }
  .bottom button:nth-child(3) {
    font-size: 11px !important;
  }
  .bottom input {
    font-size: 11px !important;
    padding: 12px 10px !important;
  }
}

.body.swal2-height-auto {
  height: 100% !important;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root {
  height: 100%;
  width: 100%;
}

html,
body {
  height: 100%;
  font-family: 'Montserrat', sans-serif;
}

.setting-dlg {
  width: 300px;
  height: 200px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.setting-select {
  background-color: crimson;
  color: #fff;
  padding: 20px;
  font-size: 20px;
  cursor: pointer;
}

.setting-select option {
  font-family: inherit !important;
  font-size: 20px !important;
  background-color: #fff;
  color: #000;
}

.setting-select option:hover {
}

.setting-wrap {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.3);

  display: flex;
  justify-content: center;
  align-items: center;
}
